import React from 'react';
import Image from 'next/image';
import { COMMUNITY_PATHS } from '@constants/index';
import { View } from 'react-native';
import Link from 'next/link';

const defaultAvatar = require('@images/defaultAvatar.webp');

const BaseAvatar = (props) => {
  const { avatar, avatarSize, identificationSource, identificationSize } =
    props;
  return (
    <div style={{ position: 'relative' }} data-buried="avatar">
      <Image
        src={avatar || defaultAvatar}
        width={avatarSize}
        height={avatarSize}
        className={'image_borderRadius_' + avatarSize + 'px'}
        layout="fixed"
        alt={'kikitrade'}
      />
      {/* 添加用户身份功能，位置和旧版本vip功能相同 */}
      {identificationSource ? (
        <View style={[{ position: 'absolute', bottom: 4, right: -1 }]}>
          <Image
            src={identificationSource}
            width={identificationSize}
            height={identificationSize}
            layout="fixed"
            alt={'kikitrade'}
          />
        </View>
      ) : null}
    </div>
  );
};

const AvatarComponent = (props) => {
  const {
    avatar,
    avatarSize = 40,
    identificationSource,
    identificationSize = 14,
    customerId
  } = props;
  if (!customerId) {
    return (
      <BaseAvatar
        avatar={avatar}
        avatarSize={avatarSize}
        identificationSource={identificationSource}
        identificationSize={identificationSize}
      />
    );
  } else {
    return (
      <Link href={`${COMMUNITY_PATHS.USER_HOME}${customerId}`}>
        {/* <a target='_blank'> */}
        <a>
          <BaseAvatar
            avatar={avatar}
            avatarSize={avatarSize}
            identificationSource={identificationSource}
            identificationSize={identificationSize}
          />
        </a>
      </Link>
    );
  }
};

export default AvatarComponent;
