import React, { useState } from 'react';

import { message } from 'antd'
import cn from 'classnames';
import { useRouter } from 'next/router'

import { getClient } from '@api/index'
import { Button } from '@element/index'
import { useAuthSession, useIsomorphicLayoutEffect } from '@lib/hooks';
import pubsub from '@lib/scripts/pubsub'
import { getLoginPath, I18n } from '@utils/index';

import styles from './index.module.css';

const styleTypeMap = {
  ghost: [styles.ghost, styles.ghost_grey],
  primary: [styles.primary],
  tint: [styles.tint]
}

export const FollowBtn = (
  {
    className, type = 'primary', size = 'xl',
    followedHidden = false, disabled = false,
    isFollow: defaultIsFollow, toCustomerId
  }) => {
  const [isFollow, setIsFollow] = useState(defaultIsFollow)
  const router = useRouter();
  const isLogin = useAuthSession({ redirectTo: null });
  useIsomorphicLayoutEffect(() => {
    setIsFollow(defaultIsFollow)
  }, [defaultIsFollow])
  useIsomorphicLayoutEffect(() => {
    pubsub.subscribe('FOLLOW_CHANGE', followChange)
    return () => {
      pubsub.unSubscribe('FOLLOW_CHANGE', followChange);
    }
  }, [])
  const followChange = (params) => {
    if (params.toCustomerId === toCustomerId) {
      setIsFollow(params.isFollow)
    }
  }
  const onClick = async () => {
    if (!isLogin) {
      router.push(getLoginPath())
      return
    }
    const client = getClient()
    const feed = client.feed('user', client.userId);
    try {
      const res = await feed[isFollow ? 'unfollow' : 'follow']?.('user', toCustomerId)
      console.log('11111222', res);
      if (res?.result) {
        message.success(I18n.t(isFollow ? 'profile_unfollowed' : 'profile_followed'))
        const newIsFollow = !isFollow
        setIsFollow(newIsFollow)
        pubsub.publish('FOLLOW_CHANGE', {
          isFollow: newIsFollow,
          toCustomerId
        })
      } else {
        message.error(I18n.t('common_network_error'))
      }
    } catch (error) {
      message.error(I18n.t('common_network_error'))
    }
  }
  return (
    <Button
      type="ghost"
      disabled={disabled}
      onClick={onClick}
      data-buried="follow-btn"
      data-buried-type={`follow-${isFollow}`}
      className={cn(typeof className === 'function' ? className({ isFollow, ...styleTypeMap }) : className, styles.btn, {
        [styles.primary]: type === 'primary',
        [styles.ghost]: type === 'ghost' && !isFollow,
        [styles.ghost_grey]: type === 'ghost' && isFollow,
        [styles.tint]: type === 'tint',
        [styles.sizeXl]: size === 'xl',
        [styles.sizeLg]: size === 'lg',
        [styles.sizeMd]: size === 'md',
        [styles.hidden]: followedHidden && isFollow
      })}>{I18n.t(isFollow ? 'profile_follow_action_followed' : 'community_follow')}</Button>
  )
}
